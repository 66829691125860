body {
  font-size: 1.4rem !important;
  background-color: #f2f2f2;
  height: 100%;
  overflow-x: hidden;
  font-family: "Tajawal", sans-serif !important;
}
a {
  cursor: pointer;
}
.top-dropdown li ul li a {
  height: 40px !important;
  line-height: 40px !important;
  color: #999 !important;
  transition: all 0.15s ease !important;
  border-right: 0px !important;
  border-left: 0px !important;
}
.top-dropdown li ul li a:hover {
  background-color: rgba(23, 23, 23, 0.9) !important;
  color: #fff !important;
}
ul.user-quickmenu li.active a,
ul.user-quickmenu li a:hover {
  color: #fff !important;
}
label {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}
.form-row {
  margin-bottom: 10px;
}

:host ::ng-deep {
  .p-paginator {
    .p-paginator-current {
      margin-left: auto;
    }
  }

  .p-progressbar {
    height: 0.5rem;
    background-color: #d8dadc;

    .p-progressbar-value {
      background-color: #607d8b;
    }
  }

  .table-header {
    display: flex;
    justify-content: space-between;
  }

  .p-calendar .p-datepicker {
    min-width: 25rem;

    td {
      font-weight: 400;
    }
  }

  .p-datatable.p-datatable-customers {
    .p-datatable-header {
      padding: 1rem;
      text-align: left;
      font-size: 1.5rem;
    }

    .p-paginator {
      padding: 1rem;
    }

    .p-datatable-thead > tr > th {
      text-align: left;
    }

    .p-datatable-tbody > tr > td {
      cursor: auto;
    }

    .p-dropdown-label:not(.p-placeholder) {
      text-transform: uppercase;
    }
  }

  /* Responsive */
  .p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
    display: none;
  }
}

@media screen and (max-width: 960px) {
  :host ::ng-deep {
    .p-datatable {
      &.p-datatable-customers {
        .p-datatable-thead > tr > th,
        .p-datatable-tfoot > tr > td {
          display: none !important;
        }

        .p-datatable-tbody > tr {
          border-bottom: 1px solid var(--layer-2);

          > td {
            text-align: left;
            display: block;
            border: 0 none !important;
            width: 100% !important;
            float: left;
            clear: left;
            border: 0 none;

            .p-column-title {
              padding: 0.4rem;
              min-width: 30%;
              display: inline-block;
              margin: -0.4rem 1rem -0.4rem -0.4rem;
              font-weight: bold;
            }

            .p-progressbar {
              margin-top: 0.5rem;
            }
          }
        }
      }
    }
  }
}
.p-datatable .p-sortable-column.p-highlight:hover {
  color: #495057 !important;
}
.p-datatable .p-sortable-column:focus {
  box-shadow: none !important;
}
.table-header span {
  font-size: 20px;
}
.table-header .p-input-icon-left {
  float: right;
}
.table-edit-action {
  font-size: 20px !important;
  color: green !important;
  cursor: pointer;
}
.table-delete-action {
  font-size: 20px !important;
  color: rgb(128, 0, 0) !important;
  cursor: pointer;
  margin-left: 10px;
}
.table-view-action {
  font-size: 20px !important;
  color: rgb(29, 36, 128) !important;
  cursor: pointer;
  margin-right: 10px;
}

.tableaction {
  display: table; /* Allow the centering to work */
  margin: 0 auto;
  width: 100%;
}

ul#horizontal-list {
  // min-width: 696px;
  list-style: none;
  // padding-top: 20px;
  padding: 0px !important;
}
ul#horizontal-list li {
  display: inline;
  margin-right: 15px;
}

.pagination-div {
  margin-top: 10px;
  border-bottom: 1px solid gainsboro;
  margin-bottom: 10px;
}
.p-link:focus {
  box-shadow: none !important;
}

//my table custom css
.country-dropdown {
  width: 100%;
}
.country-dropdown .p-dropdown {
  width: 100%;
  height: 41px;
  box-shadow: inset 0 0 1rem 0 rgb(0 0 0 / 10%);
  border-radius: 0.3rem;
}
.country-dropdown .p-inputtext {
  padding-top: 10px;
  padding-left: 10px;
  color: #363636 !important;

  font-size: 14px;
}
.p-component {
  font-size: 16px !important;
  font-family: "Tajawal", sans-serif !important;
}
.p-inputtext {
  font-size: 16px !important;
  font-family: "Tajawal", sans-serif !important;
}
.dob-dropdown {
  width: 100%;
}
.dob-dropdown .p-calendar {
  width: 100%;
}
.user-profile {
  height: 120px;
  width: 120px;
  cursor: pointer;
  border: 1px solid gray;
  margin-bottom: 10px;
}
.flag-profile {
  height: auto;
  width: 120px;
  cursor: pointer;
  border: 1px solid gray;
  margin-bottom: 10px;
}
.input-image {
  display: none;
}
.view-text {
  color: black;
  font-weight: 600;
  width: 100% !important;
}
h3.quick-menu {
  margin-top: 15px;
  margin-bottom: 0px;
}
ul.user-quickmenu {
  margin-top: 0px !important;
}
.main-app-table {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  width: auto !important;
  overflow-x: auto !important;
}
.mat-form-field {
  padding-right: 15px;
  padding-top: 12px;
}
// .btn {
//   padding: 15px 15px 10px 15px !important;
//   box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%) !important;

//   padding: 10px 15px 6px 15px !important;
//   box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%) !important;
//   width: 49%;
//   font-weight: 600 !important;
// }
.btn-pass {
  width: 100% !important;
}
.p-button {
  padding: 10px 10px !important;
  padding-top: 10px !important;
  font-weight: 600 !important;
}
.p-button-icon {
  font-size: 16px !important;
  margin-right: 5px !important;
}
.p-button-label {
  margin-top: 0px !important;
}
.special-error {
  color: red;
}

// webkit scroll
* {
  scrollbar-width: thin;
  scrollbar-color: rgb(184, 181, 181) rgb(255, 255, 255);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 7px;
}

*::-webkit-scrollbar-track {
  //   background: rgb(185, 185, 185);
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(161, 161, 161);
  border-radius: 10px;
  border: 3px solid rgb(160, 159, 158);
}
.setting-form-heading {
  color: #1b75bc;
  margin-bottom: 10px !important;
  margin-top: 15px !important;
  // position: relative;
  // overflow: hidden;
  // text-align: center;
}
// .setting-form-heading:before,
// .setting-form-heading:after {
//   position: absolute;
//   top: 51%;
//   overflow: hidden;
//   width: 50%;
//   height: 2px;
//   content: "\a0";
//   background-color: #4c8cbd;
// }
// .setting-form-heading:before {
//   margin-left: -50%;
//   text-align: right;
// }
.dashboard-count {
  margin-bottom: 0px !important;
  color: #1b75bc;
}
// .p-dropdown .p-dropdown-clear-icon {
//   right: 0.357rem !important;
// }
.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon:hover {
  color: rgb(0, 0, 0) !important;
}
.p-rating .p-rating-icon.pi-star {
  color: rgb(206, 206, 25) !important;
}
.sal-dropdown .p-dropdown-trigger-icon {
  display: none;
}

.table {
  width: 100%;
  background: white;
  -webkit-box-shadow: 0px 5px 12px -12px rgba(0, 0, 0, 0.29);
  -moz-box-shadow: 0px 5px 12px -12px rgba(0, 0, 0, 0.29);
  box-shadow: 0px 5px 12px -12px rgba(0, 0, 0, 0.29);
  // border-collapse:separate;
  //  border-spacing: 0 10px;
  thead {
    &.thead-primary {
      background: #1b75bc;
    }
    th {
      border: none;
      padding: 14px;
      font-size: 15px;
      font-weight: 600;
      color: white;
    }
    tr {
    }
  }
  tbody {
    tr {
      margin-bottom: 10px;
    }
    th,
    td {
      border: none;
      padding: 15px;
      font-size: 14px;
      background: white;
      border-bottom: 4px solid #f8f9fd;
      vertical-align: middle;
    }
    td {
      &.quantity {
        width: 10%;
      }
      .img {
        width: 100px;
        height: 80px;
      }
      .email {
        span {
          display: block;
          &:last-child() {
            font-size: 12px;
            color: rgba(0, 0, 0, 0.3);
          }
        }
      }
      .close {
        span {
          font-size: 12px;
          color: red;
        }
      }
    }
  }
}
@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Force table to not be like tables anymore */
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    border: 1px solid #ccc;
  }

  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
  }

  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }
}
.rating-list-action li {
  margin-left: 10px;
}
.box-shadow-caption {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.p-button-label {
  margin-bottom: -3px !important;
}
.card-raised {
  border: none;
  box-shadow: 0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%);
}
.d-card {
  line-height: 1.25rem;
  color: #000;
}
.border-4 {
  border-width: 0.5px !important;
}
.border-primary {
  border-color: #6200ea !important;
}
.border-start {
  border-left: none !important;
}

.d-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(148, 146, 146, 0.125);
  border-radius: 0.25rem;
}

.d-card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}
.me-2 {
  margin-right: 0.5rem !important;
}
.display-5 {
  line-height: 2rem;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 1.5rem;
  }
}
.display-5 {
  font-size: calc(1.5rem + 0.3vw);
  font-weight: 600;
  line-height: 1.2;
}

.card-text:last-child {
  margin-bottom: 0;
}

.icon-circle0 {
  height: 5rem;
  width: 5rem;
  display: flex;
  align-items: center;
  background-color: #2fade2 !important;
  justify-content: center;
  border-radius: 50%;
}
.icon-circle1 {
  height: 5rem;
  width: 5rem;
  display: flex;
  align-items: center;
  background-color: #e2a92f !important;
  justify-content: center;
  border-radius: 50%;
}
.icon-circle2 {
  height: 5rem;
  width: 5rem;
  display: flex;
  align-items: center;
  background-color: #e22f47 !important;
  justify-content: center;
  border-radius: 50%;
}
.icon-circle3 {
  height: 5rem;
  width: 5rem;
  display: flex;
  align-items: center;
  background-color: #40b349 !important;
  justify-content: center;
  border-radius: 50%;
}

.icon-circle:before {
  content: none !important;
}
.card-text {
  font-size: 20px;
}
.icon_plus_alt2 {
  color: #4db9e6;
  font-weight: 700 !important;
}
.icon_minus_alt2 {
  color: #4db9e6;
  font-weight: 700 !important;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #1b75bc !important;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent
  .mat-radio-ripple
  .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #1b75bc !important;
}
